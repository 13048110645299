<template>
  <svg
    class="apo-plus"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :d="path"
    />
  </svg>
</template>
<script lang="ts" setup>
const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
})

const path = computed(() => {
  return props.isActive
    ? 'M2.50169 8.49831H8.49831L10 8.49831L11.5017 8.49831H17.4983C18.3303 8.49831 19 9.16798 19 10C19 10.832 18.3303 11.5017 17.4983 11.5017H11.5017C11 11.5017 10.832 11.5 10 11.5C9.16798 11.5 9 11.5017 8.49831 11.5017H2.50169C1.66967 11.5017 1 10.832 1 10C1 9.16798 1.66967 8.49831 2.50169 8.49831Z'
    : 'M2.50169 8.49831H8.49831V2.50169C8.49831 1.66967 9.16798 1 10 1C10.832 1 11.5017 1.66967 11.5017 2.50169V8.49831H17.4983C18.3303 8.49831 19 9.16798 19 10C19 10.832 18.3303 11.5017 17.4983 11.5017H11.5017V17.4983C11.5017 18.3303 10.832 19 10 19C9.16798 19 8.49831 18.3303 8.49831 17.4983V11.5017H2.50169C1.66967 11.5017 1 10.832 1 10C1 9.16798 1.66967 8.49831 2.50169 8.49831Z'
})
</script>
