<template>
  <details class="details box bg-white" v-bind="attributes" @toggle="onToggle">
    <summary
      :href="href"
      class="box-padding text-green-alt text-xs lg:text-base uppercase font-medium flex gap-10 md:gap-20 items-center cursor-pointer hover:text-lime leading-tight"
      :aria-expanded="isOpen"
      :aria-controls="id + '-content'"
    >
      <IconsPlus
        class="w-[12px] h-[12px] md:w-20 md:h-20 shrink-0"
        :is-active="isOpen"
      />
      <slot name="title">
        <span>{{ title }}</span>
      </slot>
      <slot name="after-title"></slot>
    </summary>
    <div class="box-padding pt-0">
      <div class="md:pl-40">
        <slot></slot>
      </div>
    </div>
  </details>
</template>

<script lang="ts" setup>
import { slugify } from '~/helpers/slugify'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  alwaysOpen: {
    type: Boolean,
    default: false,
  },
  anchor: {
    type: String,
    default: '',
  },
})

const route = useRoute()

const isOpen = ref(false)

const id = computed(() => {
  return slugify(props.anchor || props.title)
})

const href = computed(() => {
  return '#' + id.value
})

const attributes = computed(() => {
  return {
    open: isOpen.value || props.alwaysOpen,
  }
})

watch(route, (v) => {
  if (!v.hash) {
    return
  }
  if (v.hash === href.value) {
    isOpen.value = true
  } else {
    isOpen.value = false
  }
})

function onToggle(e: Event) {
  if (e.target && e.target instanceof HTMLDetailsElement) {
    isOpen.value = e.target.open
  }
}

onMounted(() => {
  if (route.hash.toLowerCase() === href.value.toLowerCase()) {
    isOpen.value = true
  }
})
</script>

<style lang="postcss">
.details {
  summary::-webkit-details-marker,
  summary::marker {
    display: none;
    content: '';
  }
}

.details + .details {
  @apply mt-5 md:mt-15;
}

.details .content:first-child {
  margin-top: 0px;
}
</style>
